import React from "react";
import Footer from "components/pageElements/Footer";
import Header from "components/pageElements/Header";

const Layout = ({ children }) => (
	<>
		{ children }
		<Header />
		<Footer />
	</>
);

export default Layout;
